import './assets/main.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import * as Sentry from '@sentry/vue'

const app = createApp(App)

Sentry.init({
  environment: import.meta.env.MODE,
  app,
  dsn: 'https://80524cf53c0ea9d406e225c4686288a8@o4508121914212352.ingest.de.sentry.io/4508251167916112',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.browserProfilingIntegration()
  ],
  tracesSampleRate: 0.2,
  profilesSampleRate: 1.0
})

app
  .provide('supportLink', import.meta.env.VITE_SUPPORT_LINK)
  .use(router)
  .use(i18n)
  .mount('#app')
